import { json } from '@remix-run/node';
import { QueryClient, dehydrate, useQueries } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import { ElemasonEntry, useElemasonContext } from '@tectonic/elemason';
import { getHttpClientFunctionArgs, getUrlSearchParams } from '@tectonic/utils';
import { elemasonConfig } from 'app/elemason.config';
import { getPageWithData, getRoot } from '../api/pages.server';

import type { DataFunctionArgs } from '@remix-run/node';

const SLUG = '404';

const loader = async (args: DataFunctionArgs) => {
  const queryClient = new QueryClient();

  const routeParams = args.params;
  const searchParams = getUrlSearchParams(args.request.url);
  const httpClientFunctionArgs = getHttpClientFunctionArgs(args);

  await Promise.allSettled([
    queryClient.prefetchQuery({
      queryKey: ['root'],
      queryFn: () => getRoot(httpClientFunctionArgs),
    }),
    queryClient.prefetchQuery({
      queryKey: ['page', SLUG, routeParams, searchParams],
      queryFn: () => getPageWithData(httpClientFunctionArgs, SLUG),
    }),
  ]);

  return json({ dehydratedState: dehydrate(queryClient) }, { status: 404 });
};

const NotFound = () => {
  const { routeParams, searchParams } = useElemasonContext();

  const [{ data: root }, { data: page }] = useQueries({
    queries: [
      {
        enabled: false,
        queryKey: ['root'],
        queryFn: () => remixApi.getRoot(),
      },
      {
        enabled: false,
        queryFn: () => remixApi.getPage(SLUG),
        queryKey: ['page', SLUG, routeParams, searchParams],
      },
    ],
  });

  return (
    <ElemasonEntry
      root={root}
      data={page?.data}
      page={page?.page}
      config={elemasonConfig}
      key={`${JSON.stringify(routeParams)}-${JSON.stringify(searchParams)}`}
    />
  );
};

export default NotFound;

export { loader };
